/***
Customized Bootstrap Buttons
***/

/* Default buttons */

.btn {
  border-width: 0;
  padding: rem(6px) rem(13px);
  font-size: $font-size-normal;
  outline: none !important;
  background-image: none !important;
  filter: none;
  border-bottom-width: 2px;
  transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 0 !important;

  text-shadow: none;

  &.dropdown-toggle,
  .btn-group &.dropdown-toggle,
  &:hover,
  &:disabled,
  &[disabled],
  &:focus,
  &:active,
  &.active {
    outline: none !important;
    background-image: none !important;
    filter: none;
    text-shadow: none;
    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.5);
  }
}

.btn + .btn {
  margin-left: rem(5px);
}

.btn-default {
  padding: rem(6px) rem(13px);
}

.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

/* Bootstrap buttons */

.btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);

  > i {
    color: #aaa;
  }

  > i[class^='icon-'],
  > i[class*='icon-'] {
    color: darken(#999, 5%);
  }
}

.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

.btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

/* Button sizes */
.btn {
  > i {
    font-size: $icon-size-small;
  }
}

.btn-lg {
  padding: rem(10px) rem(16px);
  font-size: $font-size-medium;
  line-height: 1.33;
  vertical-align: middle;

  > i {
    font-size: $icon-size-medium;
  }
}

.btn-sm,
.btn-xs {
  padding: rem(4px) rem(10px) rem(5px) rem(10px);
  font-size: $font-size-small;
  line-height: 1.5;

  > i {
    font-size: $icon-size-small;
  }
}

.btn-xs {
  padding: rem(1px) rem(5px);

  > i {
    font-size: $icon-size-small;
  }
}

/* Small button in button group */

.btn-group {
  .input-sm {
    .btn-default {
      padding-top: rem(3px);
      padding-bottom: rem(3px);
    }
  }
}

/* Arrow link */

.btn-arrow-link {
  vertical-align: middle;
  display: inline-block;
  font-size: $font-size-small;

  > i {
    font-size: $icon-size-normal;
    line-height: 1;
    vertical-align: top;
    margin: rem(2px) rem(3px);
    color: #999;
  }

  &.btn-arrow-link-lg {
    font-size: $font-size-normal;

    > i {
      font-size: $icon-size-medium;
      line-height: 1;
      margin: rem(2px) rem(3px) rem(1px) rem(3px);
    }
  }
}

/* Circle Buttons */

.btn-circle {
  border-radius: rem(25px) !important;
}

.btn-circle-right {
  border-radius: 0 rem(25px) rem(25px) 0 !important;
}

.btn-circle-left {
  border-radius: rem(25px) 0 0 rem(25px) !important;
}

.btn-circle-bottom {
  border-radius: 0 0 rem(25px) rem(25px) !important;
}

.btn-circle-top {
  border-radius: rem(25px) rem(25px) 0 0 !important;
}

.btn-icon-only {
  height: rem(34px);
  width: rem(34px);
  text-align: center;
  padding-left: 0;
  padding-right: 0;

  > [class^='icon-'],
  > i {
    text-align: center;
    margin-top: rem(2px);
  }
}

.btn-sm {
  > [class^='glyphicon-'],
  > [class*=' glyphicon-'],
  > i {
    font-size: $font-size-small;
  }
}

.btn-xs {
  > [class^='glyphicon-'],
  > [class*=' glyphicon-'],
  > i {
    font-size: $font-size-small;
  }
}

.btn-group.btn-group-circle {
  > .btn {
    &:first-child {
      border-radius: rem(25px) 0 0 rem(25px) !important;
    }

    &:last-child {
      border-radius: 0 rem(25px) rem(25px) 0 !important;
    }
  }
}

.btn-group.btn-group-devided {
  > .btn {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.btn-group-vertical.btn-group-vertical-circle {
  > .btn {
    &:first-child {
      border-radius: rem(25px) rem(25px) 0 0 !important;
    }

    &:last-child {
      border-radius: 0 0 rem(25px) rem(25px) !important;
    }
  }
}

@if $theme-type == 'material-design' {
  $uppercase-padding: 9px 14px 8px 14px !default;
  $uppercase-padding-lg: 12px 26px 10px 26px !default;
  $uppercase-padding-sm: 6px 18px 6px 18px !default;
  $uppercase-padding-xs: 3px 8px 3px 8px !default;

  .btn {
    font-size: $font-size-small;
    font-weight: 600;
    text-transform: uppercase;
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    border-width: 0 !important;
    overflow: hidden;
    position: relative;

    @extend .md-shadow-z-1;

    .btn-group > & {
      @extend .md-shadow-z-1;
    }

    .search-form &,
    .btn-theme-panel & {
      box-shadow: none !important;
      overflow: visible;
    }

    &.btn-link {
      box-shadow: none;
      color: $link-color;

      &:hover {
        text-decoration: none;

        @extend .md-shadow-z-1;
      }
    }

    &:hover {
      @extend .md-shadow-z-2-hover;
    }

    &:active {
      @extend .md-shadow-z-3-hover;
    }

    padding: $uppercase-padding;

    > i {
      margin-top: 0;
      margin-left: 3px;
      margin-right: 3px;
    }

    &.btn-sm {
      font-size: $font-size-small;
      padding: $uppercase-padding-sm;
    }

    &.btn-xs {
      font-size: $font-size-small;
      padding: $uppercase-padding-xs;
    }

    &.btn-lg {
      font-size: $font-size-medium;
      padding: $uppercase-padding-lg;
    }

    &.btn-icon-only {
      padding-left: 0;
      padding-right: 0;

      > i {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.media-button {
  cursor: pointer !important;
  opacity: 1 !important;
  background: $brand-primary !important; /* Old browsers */
  background: linear-gradient(
    to right,
    $brand-primary 0%,
    $brand-success 100%
  ) !important;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80%;
  &:hover,
  &:active,
  &:focus {
    background: darken($brand-primary, 5) !important; /* Old browsers */
    background: linear-gradient(
      to right,
      darken($brand-primary, 5) 0%,
      darken($brand-success, 5) 100%
    ) !important;
    color: white;
  }
}
