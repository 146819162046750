h1, h2, h3, h4, h5, h5 {
  color: #222222;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 400;
}

.heading {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 4px;
  line-height: 18px;
  color: #222222;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}
.heading::before {
  content: '';
  position: absolute;
  width: 46px;
  height: 12px;
  border: 2px solid #222;
  border-bottom: 0px;
  border-color: rgba(34, 34, 34, 0.1);
  left: 50%;
  margin-left: -23px;
  top: -18px;
}
.heading::after {
  content: '';
  position: absolute;
  width: 46px;
  height: 12px;
  border: 2px solid #222;
  border-top: 0px;
  border-color: rgba(34, 34, 34, 0.1);
  left: 50%;
  margin-left: -23px;
  bottom: -18px;
}

/*==================================
    TIMELINE
==================================*/

/*-- GENERAL STYLES
------------------------------*/
.timeline {
  line-height: 1.4em;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  h1, h2, h3, h4, h5, h6 {
    line-height: inherit;
  }
}

/*----- TIMELINE ITEM -----*/

.timeline-item {
  padding-left: 40px;
  position: relative;
  &:last-child {
    padding-bottom: 0;
  }
}

/*----- TIMELINE INFO -----*/

.timeline-info {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 0 0 .5em 0;
  text-transform: uppercase;
  white-space: nowrap;
}
/*----- TIMELINE MARKER -----*/

.timeline-marker {
  position: absolute;
  top: 0; bottom: 0; left: 0;
  width: 15px;
  &:before {
    background: #7c7d55;
    border: 3px solid transparent;
    border-radius: 100%;
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    top: 4px; left: 0;
    width: 15px;
    transition: background 0.3s ease-in-out,
    border 0.3s ease-in-out;
  }
  &:after {
    content: "";
    width: 3px;
    background: #CCD5DB;
    display: block;
    position: absolute;
    top: 24px; bottom: 0; left: 6px;
  }
  .timeline-item:last-child &:after {
    content: none;
  }
}
.timeline-item:not(.period):hover .timeline-marker:before {
  background: transparent;
  border: 3px solid #7c7d55;
}

/*----- TIMELINE CONTENT -----*/

.timeline-content {
  padding-bottom: 40px;
  p:last-child {
    margin-bottom: 0;
  }
}

/*----- TIMELINE PERIOD -----*/

.period {
  padding: 0;
  .timeline-info {
    display: none;
  }
  .timeline-marker {
    &:before {
      background: transparent;
      content: "";
      width: 15px;
      height: auto;
      border: none;
      border-radius: 0;
      top: 0;
      bottom: 30px;
      position: absolute;
      border-top: 3px solid #CCD5DB;
      border-bottom: 3px solid #CCD5DB;
    }
    &:after {
      content: "";
      height: 32px;
      top: auto;
    }
  }
  .timeline-content {
    padding: 40px 0 70px;
  }
  .timeline-title {
    margin: 0;
  }
}

/*----------------------------------------------
    MOD: TIMELINE SPLIT
----------------------------------------------*/

.timeline-split {
  @media (min-width: 768px) {
    .timeline {
      display: table;
    }
    .timeline-item {
      display: table-row;
      padding: 0;
    }
    .timeline-info,
    .timeline-marker,
    .timeline-content,
    .period .timeline-info {
      display: table-cell;
      vertical-align: top;
    }
    .timeline-marker {
      position: relative;
    }
    .timeline-content {
      padding-left: 30px;
    }
    .timeline-info {
      padding-right: 30px;
    }
    .period .timeline-title {
      position: relative;
      left: -45px;
    }
  }
}

/*----------------------------------------------
    MOD: TIMELINE CENTERED
----------------------------------------------*/

.timeline-centered {
  @extend .timeline-split;
  @media (min-width: 992px) {
    &,
    .timeline-item,
    .timeline-info,
    .timeline-marker,
    .timeline-content {
      display: block;
      margin: 0;
      padding: 0;
    }
    .timeline-item {
      padding-bottom: 40px;
      overflow: hidden;
    }
    .timeline-marker {
      position: absolute;
      left: 50%;
      margin-left: -7.5px;
    }
    .timeline-info,
    .timeline-content {
      width: 50%;
    }
    > .timeline-item:nth-child(odd) .timeline-info {
      float: left;
      text-align: right;
      padding-right: 30px;
    }
    > .timeline-item:nth-child(odd) .timeline-content {
      float: right;
      text-align: left;
      padding-left: 30px;
    }
    > .timeline-item:nth-child(even) .timeline-info {
      float: right;
      text-align: left;
      padding-left: 30px;
    }
    > .timeline-item:nth-child(even) .timeline-content {
      float: left;
      text-align: right;
      padding-right: 30px;
    }
    > .timeline-item.period .timeline-content {
      float: none;
      padding: 0;
      width: 100%;
      text-align: center;
    }
    .timeline-item.period {
      padding: 50px 0 90px;
    }
    .period .timeline-marker:after {
      height: 30px;
      bottom: 0;
      top: auto;
    }
    .period .timeline-title {
      left: auto;
    }
  }
}

/*----------------------------------------------
    MOD: MARKER OUTLINE
----------------------------------------------*/

.marker-outline {
  .timeline-marker {
    &:before {
      background: transparent;
      border-color: #7c7d55;
    }
  }
  .timeline-item:hover .timeline-marker:before {
    background: #7c7d55;
  }
}

// customize react widgets multiselect
.rw-multiselect-taglist  {
  display:inline;
}

.rw-multiselect-taglist > li .rw-btn {
  margin-left: 5px;
  font-size: 1.1em;
  color: red;
}

.rw-multiselect .rw-widget-picker > div {
  vertical-align: top;
}

// fix form-control on multi browsers

.form-control {
  display: inline-block;
}



.form-control.text {
  line-height: 30px;
  font-size: $font-size-large;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.form-group.form-widget .react-datepicker__input-container > input {
  line-height: 50px;
  font-size: $font-size-large;
  height: 62px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  width: 250px;
}

// override numeric slider component style
.numeric-slider {
  overflow: hidden;
  width: 100%;
  padding: 0 10%;
  min-height: 100px;
}
.rc-slider-track {
  background-color: $brand-complementary-color !important;
}
.rc-slider-handle {
  border: solid 2px $brand-complementary-color !important;
  background: lighten($brand-complementary-color, 40%) !important;
}

.rc-slider-dot-active {
  border: 2px solid $brand-complementary-color !important;
  background: #10817b !important;
}

// pdf container
.pdf-container {
  background: rgba(230, 230, 230, 0.5) !important;
  padding: 20px 10px 1px !important;
  text-align: center;
  border-radius: 3px;
}

.close-icon {
  display: block;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border-width: 5px;
  border-style: solid;
  border-color: #958b8a;
  border-radius: 100%;
  background: linear-gradient(
      -45deg,
      transparent 0%,
      transparent 40%,
      white 40%,
      white 60%,
      transparent 60%,
      transparent 100%
    ),
    linear-gradient(
      45deg,
      transparent 0%,
      transparent 40%,
      white 40%,
      white 60%,
      transparent 60%,
      transparent 100%
    );
  background-color: #958b8a;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 5px 2px 2px rgba(0, 0, 0, 0.25);
  }
}

// pdf container
.pdf-container {
  background: #eee;
  padding: 10px;
  text-align: center;
  .ReactPDF__Page__canvas {
    margin: 0 auto;
    max-width: 100%;
    height: auto !important;
  }
}

// redux toastr
.redux-toastr {
  .toastr {
    .toastr-status {
      &.success {
        background-color: $toastr-successBgColor;
      }

      &.warning {
        background-color: $toastr-warningBgColor;
      }

      &.info {
        background-color: $toastr-infoBgColor;
      }

      &.error {
        background-color: $toastr-errorBgColor;
      }
    }

    &.rrt-info {
      color: $toastr-infoColor;
      background-color: $toastr-infoBgColor;

      .rrt-progressbar {
        background-color: darken($toastr-infoBgColor, 15%);
      }

      .rrt-left-container {
        .toastr-icon {
          fill: $toastr-infoColor;
        }
      }
    }

    &.rrt-success {
      color: $toastr-successColor;
      background-color: $toastr-successBgColor;

      .rrt-progressbar {
        background-color: darken($toastr-successBgColor, 15%);
      }

      .rrt-left-container {
        .toastr-icon {
          fill: $toastr-successColor;
        }
      }
    }

    &.rrt-warning {
      color: $toastr-warningColor;
      background-color: $toastr-warningBgColor;

      .rrt-progressbar {
        background-color: darken($toastr-warningBgColor, 15%);
      }

      .rrt-left-container {
        .toastr-icon {
          fill: $toastr-warningColor;
        }
      }
    }

    &.rrt-error {
      color: $toastr-errorColor;
      background-color: $toastr-errorBgColor;

      .rrt-progressbar {
        background-color: darken($toastr-errorBgColor, 15%);
      }

      .rrt-left-container {
        .toastr-icon {
          fill: $toastr-errorColor;
        }
      }
    }
  }
}

/***
Page Loading
***/

.slide-appear {
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
  opacity: 0;
  &.slide-appear-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.6s linear;
  }
}

.slide-enter {
  opacity: 0;
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
  &.slide-enter-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.2s linear 0.4s;
  }
}

.slide-leave {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  &.slide-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    transition: all 0.2s linear;
  }
}

// Customize cards
.paper {
  // portlet body is not into a portlet element but into a card
  .portlet-body {
    clear:both;
  }
}
