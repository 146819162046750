//## Base theme color file

/****
Boby
****/

body {
  background-color: $bg-color;

  &.page-md {
    background: lighten($bg-color, 5%);
  }
}

/******
General 
******/

/* Theme Font Color */
.theme-font {
  color: $brand-main-color !important;
}

/* Pace - Page Progress */
.pace .pace-progress {
  background: $brand-main-color;
}

/* Theme Light Portlet */
.portlet.light {
  .btn.btn-circle.btn-icon-only.btn-default {
    border-color: lighten(#acb5c3, 5%);

    > i {
      font-size: $icon-size-small;
      color: darken(#acb5c3, 2%);
    }

    &:hover,
    &.active {
      color: #fff;
      background: $brand-main-color;
      border-color: $brand-main-color;

      > i {
        color: #fff;
      }
    }
  }
}

/******
Page Header 
******/

.page-header {
  background-color: $header-bg-color;

  /* Page Header Top */
  .page-header-top {
    &.fixed {
      background: $header-bg-color !important;
      box-shadow: $header-top-menu-fixed-border-box;
    }
  }

  /* Page Header Menu */
  .page-header-menu {
    background: $header-hor-menu-bg-color;

    &.fixed {
      box-shadow: $header-hor-menu-fixed-border-box;
    }
  }
}

/******
Page Footer 
******/
.page-prefooter {
  background: $footer-pre-bg-color;
  color: $footer-pre-font-color;

  h2 {
    color: $brand-main-color;
  }

  .subscribe-form {
    .form-control {
      background: darken($footer-pre-bg-color, 9%);
      border-color: darken($footer-pre-bg-color, 9%);
      color: $footer-pre-font-color;

      @include placeholder(darken($footer-pre-font-color, 5%));
    }

    .btn {
      @include button-variant(#fff, lighten($brand-main-color, 3%), '');
    }
  }
}

/* Scroll Top */

.scroll-to-top {
  > i {
    color: $footer-scroll-top-icon-color;
    font-size: rem(32px);
    @include opacity(0.7);
  }
}

textarea {
  resize: vertical;
}

@media (min-width: $screen-md-min) {
  /* 992px */
  .page-header {
    /* Page Header Menu */
    .page-header-menu {
      &.fixed {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: $zindex-header-fixed;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  /* 991px */
  .page-header {
    .page-header-menu {
      background: $page-content-bg-color;
    }
  }
}

@media (max-width: $screen-xs-min) {
  /* 480px */

  .page-header {
    /* Top menu */
    .top-menu {
      .page-header-fixed-mobile & {
        background-color: $header-bg-color;
      }
    }
  }
}

/****
 CSS3 Spinner Bar
****/

.page-spinner-bar > div,
.block-spinner-bar > div {
  background: lighten($brand-main-color, 5%);
}
